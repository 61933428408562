<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="coloredItems"
      item-key="name"
      disable-pagination
      :hide-default-footer="true"
      disable-sort
      class="scroll-header-tables geo-table"
    >
      <template v-slot:item="{ item }">
        <tr :style="{ background: item.color }">
          <td> {{ item.name }}</td>
          <td> {{ item.label }}</td>
          <td> {{ typeById(item.eavType.id) }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { filterAttributes } from '@/components/utils/common'

export default {
  name: 'BaseGeoJsonExample',
  props: {
    preGeoJson: Array
  },
  data () {
    return {
      singleExpand: false,
      expanded: [],
      headers: [
        {
          text: 'Атрибут',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Название', value: 'label' },
        { text: 'Описание', value: 'id' }
      ],
      filteredPreGeoJson: [],
      groupColors: {}
    }
  },
  computed: {
    itemsWithRelated () {
      const newArray = []
      let groupId = 1 // начальное значение groupId
      for (let item of this.filteredPreGeoJson) {
        if (item.relatedAttributes && item.relatedAttributes.length > 0) {
          /*newArray.push({
            ...item,
            groupId: groupId // добавляем свойство groupId для элемента
          })*/
          for (const relatedItem of item.relatedAttributes) {
            newArray.push({
              ...relatedItem,
              groupId: groupId // добавляем свойство groupId для связанных элементов
            })
          }
          groupId++ // увеличиваем groupId для следующей группы
        } else {
          newArray.push(item)
        }
      }
      return newArray
    },
    coloredItems () {
      const colors = {} // объект для хранения цветов групп
      const coloredItems = this.itemsWithRelated.map(item => {
        if (item.groupId) {
          if (!colors[item.groupId]) {
            // генерируем случайный цвет для группы, если он еще не сгенерирован
            colors[item.groupId] = this.getRandomColor()
          }
          return {
            ...item,
            color: colors[item.groupId] // добавляем свойство color для элемента
          }
        }
        return item
      })
      return coloredItems
    }
  },
  methods: {
    typeById (id) {
      let type = ''
      switch (id) {
        case 1:
          type = 'Строка'
          break
        case 2:
          type = 'Строчное значение, должно быть разделено запятой'
          break
        case 7:
          type = 'Число'
          break
        case 8:
          type = 'Строка, должна быть разделена запятой и соответствовать значению, указанному в справочнике'
          break
        case 9:
          type = 'Составное значение, имеет список связанных атрибутов'
          break
        case 10:
          type = 'Составное значение'
          break
        case 11:
          type = 'Булево'
          break
        case 14:
          type = 'Групповое значение'
      }
      return type
    },
    getRandomColor () {
      const existingColors = [] // массив для хранения уже сгенерированных цветов
      let color

      do {
        const hue = Math.floor(Math.random() * 360) // генерируем случайное значение оттенка (0-359)
        const saturation = Math.floor(Math.random() * 10) + 90 // генерируем случайное значение насыщенности
        const lightness = Math.floor(Math.random() * 10) + 90 // генерируем случайное значение яркости
        color = this.hslToRgb(`hsl(${hue}, ${saturation}%, ${lightness}%)`)
      } while (existingColors.includes(color)) // проверяем, существует ли уже такой цвет

      existingColors.push(color) // добавляем сгенерированный уникальный цвет в массив

      return color
    },
    hslToRgb (hslColor) {
      // преобразуем HSL цвет в RGB цвет
      const hslValues = hslColor.match(/(\d+(\.\d+)?)/g).map(Number)
      const h = hslValues[0] / 360
      const s = hslValues[1] / 100
      const l = hslValues[2] / 100

      let r, g, b

      if (s === 0) {
        r = g = b = l // если насыщенность равна 0, то цвет - оттенок серого
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1
          if (t > 1) t -= 1
          if (t < 1 / 6) return p + (q - p) * 6 * t
          if (t < 1 / 2) return q
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
          return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q

        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
      }
      // преобразуем значения RGB в формат #RRGGBB
      const toHex = x => {
        const hex = Math.round(x * 255).toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`
    },
    filterPreGeoJson () {
      this.filteredPreGeoJson = filterAttributes(this.preGeoJson, ['id'])
    }
  },
  created () {
    this.filterPreGeoJson()
  }
}
</script>

<style scoped>
  /deep/ .geo-table.v-data-table .v-data-table__wrapper {
    max-height: calc(56vh - 22px) !important
  }
</style>
