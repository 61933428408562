<template>
  <div>
    <v-alert
      :color="color"
      dense
      :icon="icon"
      :type="type"
      :class="'ma-0 ' + customClass"
      :outlined="outlined"
      :colored-border="coloredBorder"
      :max-width="maxWidth"
    >
      <slot></slot>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'BaseAlertNew',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: false
    },
    coloredBorder: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>