export default [
  { name: 'object_name', label: 'Наименование паспорта', eavType: { id: 1, name: 'string' } },
  {
    name: 'territorial_affiliation',
    label: 'Административно территориальная принадлежность',
    eavType: { id: 1, name: 'string' }
  },
  { name: 'address', label: 'Адрес', eavType: { id: 1, name: 'string' } },
  {
    name: 'executing_organization',
    label: 'Организация выполняющая паспортизацию',
    eavType: { id: 1, name: 'string' }
  },
  { name: 'classification_code', label: 'Классификационный код', eavType: { id: 1, name: 'string' } },
  {
    name: 'classification_code_detail',
    label: 'Расшифровка классификационного кода',
    eavType: { id: 1, name: 'string' }
  },
  { name: 'land_function', label: 'Функциональное назначение земель', eavType: { id: 1, name: 'string' } },
  { name: 'person_in_charge', label: 'Ответственное лицо', eavType: { id: 1, name: 'string' } },
  { name: 'origin_executor', label: 'Исполнитель первичной паспортизации', eavType: { id: 1, name: 'string' } },
  { name: 'owner', label: 'Ответственный владелец', eavType: { id: 1, name: 'string' } },
  { name: 'origin_passportization_date', label: 'Дата первичной паспортизации', eavType: { id: 1, name: 'string' } },
  { name: 'using_mode', label: 'Режимы охраны и использования', eavType: { id: 1, name: 'string' } },
  { name: 'length', label: 'Длина участка пг.м', eavType: { id: 1, name: 'string' } },
  { name: 'width', label: 'Ширина участка м', eavType: { id: 1, name: 'string' } },
  { name: 'area', label: 'Площадь кв.м', eavType: { id: 1, name: 'string' } }
]
