<template>
  <div v-if="info">
    <BaseImportProgress
      v-model="info"
    />
    <BaseImportExpansionPanels
      v-model="info"
    />
  </div>
</template>

<script>
import BaseImportExpansionPanels
  from '@/components/base/baseImportPanel/content/baseImportDetail/BaseImportExpansionPanels'
import BaseImportProgress from '@/components/base/baseImportPanel/content/baseImportDetail/BaseImportProgress'

export default {
  name: 'BaseImportDetail',
  components: { BaseImportProgress, BaseImportExpansionPanels },
  props: {
    info: Object
  }
}
</script>

<style scoped>

</style>