<template>
  <div>
    <div class="d-flex">
      <BaseUploader
        v-model="value"
        :file-type="fileType"
        @uploaded="$emit('uploaded', $event)"
      />
      <BaseBtn
        v-if="$route.name === 'map' && fileType === 'geojson' && !isHideManualChange"
        color="primary"
        icon="mdi-draw"
        title="Установить/Изменить вручную"
        small
        is-small-icon
        depressed
        @click="handleMapGeometry"
      />
    </div>
    <BaseUploaderTabs
      v-if="$route.name === 'passport' || passportEntity"
      :passport-entity="passportEntity"
    />
  </div>
</template>

<script>
import BaseUploader from '@/components/base/baseImportPanel/content/baseImportUploader/BaseUploader'
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseUploaderTabs from '@/components/base/baseImportPanel/content/baseImportUploader/BaseUploaderTabs'

export default {
  name: 'BaseImportUploader',
  components: { BaseUploaderTabs, BaseBtn, BaseUploader },
  props: {
    value: {
      type: Object,
      required: true
    },
    fileType: {
      type: String,
      required: true
    },
    currentRole: {
      type: String,
      required: true
    },
    isHideManualChange: {
      type: Boolean
    },
    passportEntity: {
      type: Array
    }
  },
  methods: {
    handleMapGeometry () {
      this.$emit('geometry')
    }
  }
}
</script>

<style scoped>

</style>