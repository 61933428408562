<template>
  <div>
    <v-radio-group
      class="mt-0"
      label="Способ выбора проекции"
      v-model="currentRadio"
    >
      <v-radio
        value="list"
        label="Выбрать из списка"
      ></v-radio>
      <v-radio
        value="input"
        label="Задать вручную"
      ></v-radio>
    </v-radio-group>
    <div style="width: 70%">
      <v-select
        v-model="crs"
        :items="listOfProjection"
        outlined
        dense
        item-text="projection"
        item-value="value"
        label="Выбрать из списка"
        v-if="currentRadio === 'list'"
      >
      </v-select>
      <v-textarea
        v-if="currentRadio === 'input'"
        dense
        outlined
        v-model="crs"
        placeholder="Введите правку координат"
      >
      </v-textarea>
    </div>
  </div>
</template>

<script>
import { listOfProjection } from '@/components/utils/projection'

export default {
  name: 'BaseSelectCrs',
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      listOfProjection,
      crs: listOfProjection[0].value,
      currentRadio: 'list'
    }
  },
  watch: {
    crs: {
      handler () {
        if (!this.params.params.crs) {
          this.$emit('update:params', {
            ...this.params,
            params: {
              ...this.params.params,
              crs: this.crs
            }
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>