<template>
  <v-stepper
    v-model="value"
    :color="color"
    :dark="dark"
    :vertical="vertical"
    :alt-labels="altLabels"
    class="withoutBorder"
  >

    <v-stepper-header class="withoutBorder justify-start">
      <v-stepper-step
        v-for="(step, index) in steps"
        :key="index"
        :step="index + 1"
        :complete="index + 1 < value"
        :editable="step.editable"
        :error="step.error"
        :disabled="step.disabled"
        :alt-label="step.altLabel"
        :alt-color="step.altColor"
        class="pa-4 pt-0"
      >
        <v-icon v-if="step.icon">{{ step.icon }}</v-icon>
        {{ step.title }}
        <span v-if="step.description">{{ step.description }}</span>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="withoutBorder">
      <v-stepper-content
        v-for="(step, index) in steps"
        :key="index"
        :step="index + 1"
        class="pa-4 pt-0"
      >
        <slot :name="step.slot"></slot>
      </v-stepper-content>
    </v-stepper-items>

    <div class="flex justify-end pl-4">
      <slot name="back-button"></slot>
      <slot name="next-button"></slot>
      <slot name="end-button"></slot>
    </div>

  </v-stepper>
</template>

<script>
export default {
  name: 'BaseStepper',
  props: {
    value: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    dark: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    altLabels: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.withoutBorder {
  border: none;
  box-shadow: none;
}

</style>