<template>
  <BaseStepper
    v-model="activeStep"
    :steps="steps"
    color="secondary"
  >
    <template #step-1-content>
      <div
        v-if="geometry && geometry.type === 'Polygon' || geometry && geometry.type === 'MultiPolygon'"
        class="mb-2"
      >
        Площадь: {{ squarePolygon }} <span>км<sup>2</sup></span>
      </div>
      <BaseImportUploader
        v-model="paramsToUpload"
        :key="firstStepKey"
        :file-type="fileType"
        :current-role="currentRole"
        :passport-entity="passportEntity"
        @uploaded="handleSecondStep"
        @geometry="handleMapGeometry"
        :is-hide-manual-change="isHideManualChange"
      >
      </BaseImportUploader>
    </template>
    <template #step-2-content>
      <BaseImportAlert
        :info="afterValidate"
      />
    </template>
    <template #step-3-content>
      <BaseSelectCrs
        v-if="fileType === 'geojson'"
        :params.sync="paramsToStart"
      />
      <!--      поле для названия подложки (только в подложках)-->
      <v-text-field
        v-else-if="fileType === 'tif' || fileType === 'tiff' || fileType === 'zip'"
        class="mt-2"
        clearable
        outlined
        label="Введите название слоя"
        v-model="paramsToStart.params.label"
        dense
      />
    </template>
    <template #step-4-content>
      <BaseImportDetail
        :info="importDetail"
      />
    </template>
    <template #back-button>
      <BaseBtn
        v-if="activeStep > 1 && activeStep <= previousStepEndAfter"
        class="ma-1"
        title="Назад"
        @click="previousStep"
      />
    </template>
    <template #next-button>
      <BaseBtn
        v-if="activeStep < steps.length && activeStep > nextStepStartAfter"
        class="ma-1"
        title="Далее"
        color="primary"
        :disabled="afterValidate === 'error'"
        @click="nextStep"
      />
    </template>
    <template #end-button>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <BaseBtn
              v-show="activeStep === steps.length"
              class="ma-1"
              title="Завершить"
              color="success"
              :disabled="!importEnd"
              @click="endImport"
            />
          </div>
        </template>
        Доступна после завершения парса, чтобы свернуть окно - нажмите крестик справа вверху
      </v-tooltip>
    </template>
  </BaseStepper>
</template>

<script>
import BaseStepper from '@/components/base/UI/BaseStepper'
import steps from '@/components/base/baseImportPanel/steps'
import BaseImportUploader from '@/components/base/baseImportPanel/content/BaseImportUploader'
import BaseImportAlert from '@/components/base/baseImportPanel/content/BaseImportAlert'
import { cleanClone } from '@/components/utils/common'
import BaseSelectCrs from '@/components/base/baseImportPanel/content/BaseSelectCrs'
import BaseImportDetail from '@/components/base/baseImportPanel/content/BaseImportDetail'
import BaseBtn from '@/components/base/UI/BaseBtn'
import { urlTypesLoad } from '@/config/common'
import { areaPolygon } from '@/components/utils/map/common'

const urlValidate = 'filemanager/file/validate'

export default {
  name: 'BaseImportPanel',
  components: {
    BaseBtn,
    BaseImportDetail,
    BaseSelectCrs,
    BaseImportAlert,
    BaseImportUploader,
    BaseStepper
  },
  props: {
    nextStepStartAfter: {
      type: Number
    },
    previousStepEndAfter: {
      type: Number
    },
    fileType: {
      type: String,
      required: true
    },
    paramsToUpload: {
      type: Object,
      required: true
    },
    isHideManualChange: {
      type: Boolean
    },
    passportEntity: {
      type: Array
    },
    geometry: {
      type: Object
    }
  },
  data () {
    return {
      steps,
      activeStep: 1,
      paramsToStart: cleanClone(this.paramsToUpload),
      paramsToStatus: cleanClone(this.paramsToUpload),
      afterValidate: null,
      importEnd: false,
      importDetail: null,
      firstStepKey: 0
    }
  },
  watch: {
    paramsToUpload: {//нужно для карты
      handler () {
        if (this.paramsToUpload.params.eavEntityId !== this.paramsToStart.params.eavEntityId) {
          this.paramsToStart.params.eavEntityId = this.paramsToUpload.params.eavEntityId
          this.paramsToStatus.params.eavEntityId = this.paramsToUpload.params.eavEntityId
        }
        if (this.paramsToUpload.params.passportId !== this.paramsToStart.params.passportId) {
          this.paramsToStart.params.passportId = this.paramsToUpload.params.passportId
          this.paramsToStatus.params.passportId = this.paramsToUpload.params.passportId
        }
      },
      deep: true
    },
    importDetail: { // костыль, который нужен потому что на сервере на parsingGeometry не считает парсед каунт
      handler () { // убрать после правок на сервере и метод this.parsedCountCorrect
        if (this.importDetail.status === 'success' && this.importDetail.parsingType === 'geometry') {
          this.parsedCountCorrect()
        }
      }
    },
    activeStep: {
      handler (newVal, oldVal) {
        if (oldVal === 2 && newVal === 1) {
          this.firstStepKey++
        }
      }
    }
  },
  computed: {
    squarePolygon () {
      if (this.geometry) {
        return areaPolygon(this.geometry).toFixed(3)
      }
    },
    currentRole () {
      return this.$store.getters['auth/getCurrentRole']
    },
    isFileTypeForLayersImport () {
      return this.fileType === 'tif' || this.fileType === 'tiff'
    }
  },
  methods: {
    parsedCountCorrect () {
      this.importDetail.parsedCount = this.importDetail.totalCount
    },
    previousStep () {
      if (this.isFileTypeForLayersImport && this.activeStep === 3) {
        this.activeStep -= 2
      } else {
        this.activeStep--
      }
    },
    nextStep () {
      if (this.afterValidate?.status === 'success' && this.activeStep === 2) {
        this.activeStep += 2
        this.start()
      } else if ((this.paramsToStart.params.crs || this.paramsToStart.params.label) && this.activeStep === 3) {
        // для подложек
        this.activeStep++
        this.create()
      } else if (!this.paramsToStart.params.label && this.isFileTypeForLayersImport) {
        this.activeStep += 2
      } else {
        this.activeStep++
      }
    },
    endImport () {
      this.$emit('parse:completed')
      this.activeStep = 1
    },
    handleSecondStep (data) {
      if (this.fileType === 'geojson') {
        this.validate(data)
        this.nextStep()
      } else if (data.fileName) {
        this.paramsToStart.params.fileName = data.fileName
        this.nextStep()
      } else {
        throw new Error('Ошибка при загрузке файла')
      }
    },
    async validate (data) {
      const response = await this.$store.dispatch('api/post', {
        url: urlValidate,
        data: data
      })
      if (response.data) {
        this.afterValidate = response.data
        if (response.data.status === 'success') {
          this.paramsToStart.params.crs = response.data.crs
        }
        if (response.data.status !== 'error') {
          this.paramsToStart.params.fileName = data.fileName
        }
      }
      return true
    },
    async start () {
      const response = await this.$store.dispatch('api/post', {
        url: urlTypesLoad.start,
        data: this.paramsToStart
      })
      if (response.data?.id) {
        this.paramsToStatus.params.id = response.data.id
        this.$nextTick(() => {
          if (this.paramsToStatus.params.id) {
            this.status()
          }
        })
      }
      return true
    },
    async status () {
      const response = await this.$store.dispatch('api/post', {
        url: urlTypesLoad.status,
        data: this.paramsToStatus
      })
      if (response.success && response.data) {
        if (response.data.status === 'parse' && this.paramsToStatus.params.id) {
          setTimeout(async () => this.status(), 8000)
        } else {
          this.importEnd = true
        }
        this.importDetail = response.data
      }
      return true
    },
    async create () { // для подложек, тут сервер ждет query, а не params, как в остольных
      let objectToSend = {}
      objectToSend.query = this.paramsToStart.params
      const response = await this.$store.dispatch('api/post', {
        url: 'map/layer/create',
        data: objectToSend
      })
      if (response.data.id) {
        this.importDetail = response.data
        this.importEnd = true
      }
      return true
    },
    handleMapGeometry () {
      this.$emit('geometry')
    }
  }
}
</script>

<style scoped>

</style>