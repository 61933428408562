<template>
  <uploader
    ref="uploader"
    :options="uploadConfig"
    class="v-btn mb-4"
    @file-added="fileAdded"
  >
    <uploader-unsupport></uploader-unsupport>
    <uploader-btn
      :attrs="attrs"
      class="primary rounded white--text"
    >
      <v-icon
        small
        color="white"
        class="mr-2"
      >mdi-cloud-braces
      </v-icon>
      Загрузить данные <b>.{{ fileType }}</b>
    </uploader-btn>
<!--    <uploader-list></uploader-list>-->
  </uploader>
</template>

<script>
import { Config } from '@/config'

const URL_TO_UPLOAD = 'filemanager/file/upload'

export default {
  name: 'BaseUploader',
  props: {
    value: Object,
    fileType: String,
    maxFileSize: Number,
    currentRole: String
  },
  data () {
    return {
      attrs: {
        accept: '.' + this.fileType
      }
    }
  },
  computed: {
    uploadConfig () {
      let self = this
      return {
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: Config.baseUrl + URL_TO_UPLOAD + '?currentRole=' + this.currentRole,
        testChunks: false,
        chunkSize: 1024 * 1024,
        headers: {
          'Authorization': this.$store.getters['auth/getJwt']
        },
        processResponse (response, cb) {
          if (response) {
            response = JSON.parse(response)
            if (response.error) {
              self.$store.dispatch('systemMessages/error', response.error)
            } else if (response.xdebug_message) { // проверка для тестов с локальным сервером
              self.$store.dispatch('systemMessages/error', self.exceptionText(response.xdebug_message))
            }  else if (response.data.status === 'uploaded') {
              self.$emit('uploaded', { fileName: response.data.fileName })
            }
          }
          cb(null, response)
        },
        processParams (params) {
          for (let key in self.value) {
            if (self.value.hasOwnProperty(key) && key !== 'params') {
              params[key] = self.value[key]
            }
          }
          for (let key in self.value.params) {
            if (self.value.params.hasOwnProperty(key)) {
              params[key] = self.value.params[key]
            }
          }
          return params
        }
      }
    }
  },
  methods: {
    /*stopUploading () { // нужен ли? работал ли?
      this.$refs.uploader.uploader.cancel()
    },*/
    exceptionText (xdebug_message) {
      let regex = /Exception:\s*([\p{Script=Cyrillic}\p{P}\s]+)/gu
      let match = regex.exec(xdebug_message)
      return match ? match[1] : ''
    },
    fileAdded (value) {
      let isAllow = false
      if (value) {
        if (value.size > this.maxFileSize) {
          isAllow = false
          this.$store.dispatch(
            'systemMessages/error',
            {
              text: `Размер файла должен быть не больше ${this.bytesToSize(this.maxFileSize, ' ')}`,
              time: 7000
            },
            { root: true }
          )
        }
      } else {
        isAllow = true
      }
      return isAllow
    }
  }
}
</script>

<style scoped>

</style>