<template>
  <div v-if="info && info.status">
    <BaseAlertNew
      v-if="alertSelect === statusSuccess"
      color="success"
      custom-class="rounded-xl"
    >
      <div class="white--text">
        <p class="mb-5">
          <span class="text-h5"><b>{{ info.fileName }}</b></span>
        </p>
        <div class="mt-5">
          <p class="mb-0">
            <span>Проекция: <b>{{ info.crs }}</b></span><br>
          </p>
        </div>
      </div>
    </BaseAlertNew>
    <BaseAlertNew
      v-else-if="alertSelect === statusWarning"
      color="orange lighten-1"
      custom-class="rounded-xl"
    >
      <div class="white--text">
        <p class="mb-5">
          <span class="text-h5"><b> {{ info.fileName }} </b></span>
        </p>
        <p class="mt-5 mb-0">
          <span><b>Не указана проекция из какой нужно трансформировать в EPSG:4326</b></span><br>
        </p>
      </div>
    </BaseAlertNew>
    <BaseAlertNew
      v-else
      color="red lighten-2"
      custom-class="rounded-xl"
    >
      <div class="white--text">
        <p>
          <span class="text-h5"><b>Ошибка</b></span>
        </p>
        <p>
          <span><b>Ошибка: неправильная структура данных, ознакомтесь с подсказкой</b></span>
        </p>
      </div>
    </BaseAlertNew>
  </div>
  <v-progress-linear
    v-else
    color="primary"
    indeterminate
    rounded
    height="13"
  ></v-progress-linear>
</template>

<script>
import BaseAlertNew from '@/components/base/UI/BaseAlertNew'

export default {
  name: 'BaseParserDetail',
  components: { BaseAlertNew },
  props: {
    info: {
      type: Object
    }
  },
  data () {
    return {
      statusSuccess: 'success',
      statusWarning: 'warning',
      statusError: 'error'
    }
  },
  computed: {
    alertSelect () {
      switch (this.info?.status) {
        case this.statusSuccess:
          return this.statusSuccess
        case this.statusWarning:
          return this.statusWarning
        case this.statusError:
          return this.statusError
      }
    }
  }
}
</script>

<style scoped>

</style>