<template>
  <v-expansion-panels
    flat
    accordion
  >
    <v-expansion-panel
      v-if="value.items && value.items.length"
      outlined
    >
      <v-expansion-panel-header class="pa-0">Ошибки:</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-expansion-panels
          flat
          accordion
        >
          <v-expansion-panel
            class="panel-element"
            v-for="item in value.items"
            :key="item.id"
          >
            <v-expansion-panel-header class="pa-0">
              <div class="subtitle-2">Объект {{ item.sourceObject ? item.sourceObject.id : '' }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(subitem, index) in item.errorText.split('\n')"
                :key="index"
              >
                {{ subitem }}
              </div>
              <div v-if="item.sourceObject">
                <div class="mt-4">
                  <a
                    style="border-bottom: 1px dashed"
                    @click="show=!show"
                  >
                    Объект целиком
                  </a>
                </div>
                <div
                  class="mt-2"
                  v-show="show"
                >
                  {{ item.sourceObject }}
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'BaseImportExpansionPanels',
  props: {
    value: Object
  },
  data () {
    return {
      show: false
    }
  }
}
</script>

<style scoped>

</style>