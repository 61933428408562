export default [
  {
    title: 'Загрузка файла',
    slot: 'step-1-content',
    altLabel: 'Step one',
    altColor: 'red'
  },
  {
    title: 'Валидация файла',
    slot: 'step-2-content',
    altLabel: 'Step two',
    altColor: 'green'
  },
  {
    title: 'Дополнительные параметры',
    slot: 'step-3-content',
    altLabel: 'Step three',
    altColor: 'blue'
  },
  {
    title: 'Статус загрузки',
    slot: 'step-4-content',
    altLabel: 'Step three',
    altColor: 'blue'
  }
]