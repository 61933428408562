<template>
  <div>
    <pre>
      <code>{{ code }}</code>
    </pre>
  </div>
</template>

<script>
export default {
  name: 'BaseCodeBlock',
  props: {
    code: Object || String
  }
}
</script>

<style scoped>
code {
  border-radius: 3px;
  border: 1px solid #efefef;
  color: #595959;
  background-color: #f8f8f8;
  white-space: pre;
  line-height: 1.4rem;
  text-align: left;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  margin-bottom: 1.55rem;
}
pre code {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}
</style>