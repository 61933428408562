<template>
  <div>
    <v-progress-linear
      v-model="value.parsedCount/value.totalCount*100"
      rounded
      height="12"
      color="green"
      class="progress-linear-disabled"
    >
    </v-progress-linear>
    <div class="d-flex">
      <div class="mr-2">
          <span v-if="value.totalCount">
            Всего объектов: {{ value.totalCount }}
          </span>
      </div>
      <div class="mr-2">
          <span v-if="value.parsedCount">
            Импортировалось всего: {{ value.parsedCount }}
          </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImportProgress',
  props: {
    value: Object
  }
}
</script>

<style scoped>
.progress-linear-disabled {
  pointer-events: none; /* Отключение событий мыши */
}
</style>